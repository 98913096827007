import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import Iconify from './Iconify';

export default function SearchItem({
  onSearch,
  placeholder = 'Search...',
  initialValue = '',
}: {
  onSearch: (value: string) => void;
  placeholder?: string;
  initialValue?: string;
}) {
  const [searchTerm, setSearchTerm] = useState<string>(initialValue);

  const debouncedOnSearch = useDebouncedCallback((value: string) => {
    onSearch(value);
  }, 500);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    debouncedOnSearch(event.target.value);
  };

  return (
    <TextField
      fullWidth
      value={searchTerm}
      onChange={handleSearchChange}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        ),
      }}
    />
  );
}
