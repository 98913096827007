import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Outlet } from 'react-router';

import { SettingsDrawer } from '@/components';
import Layout from '@/layout';

export default function App() {
  const { getIdTokenClaims, logout } = useAuth0();

  useEffect(() => {
    const checkToken = async () => {
      const claims = await getIdTokenClaims();
      if (claims?.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (claims.exp < currentTime) {
          logout({ logoutParams: { returnTo: window.location.origin } });
        }
      }
    };
    checkToken();
  }, [getIdTokenClaims, logout]);

  return (
    <Layout>
      <SettingsDrawer />
      <Outlet />
    </Layout>
  );
}
