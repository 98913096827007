import { inputLabelClasses } from '@mui/material/InputLabel';
import { radioClasses, RadioProps } from '@mui/material/Radio';
import { Theme } from '@mui/material/styles';

export function radio(theme: Theme) {
  return {
    // CHECKBOX, RADIO, SWITCH
    MuiFormLabel: {
      styleOverrides: {
        root: {
          [`&.${inputLabelClasses.focused}`]: {
            color: theme.palette.text.primary,
          },
          [`&.${inputLabelClasses.error}`]: {
            color: theme.palette.error.main,
          },
          [`&.${inputLabelClasses.disabled}`]: {
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.body2,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: RadioProps }) => {
          const { color } = ownerState;

          return {
            padding: theme.spacing(1),
            ...(color === 'default' && {
              [`&.${radioClasses.checked}`]: {
                color: theme.palette.text.primary,
              },
            }),
            [`&.${radioClasses.disabled}`]: {
              color: theme.palette.action.disabled,
            },
          };
        },
      },
    },
  };
}
