import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { useLocalStorage } from '@/hooks/useLocalStorage';

type SettingsValueProps = {
  themeMode: 'light' | 'dark';
  themeLayout: 'vertical' | 'mini';
};

type SettingsContextProps = SettingsValueProps & {
  onUpdate: (name: string, value: string | boolean) => void;
  open: boolean;
  onToggle: VoidFunction;
  onClose: VoidFunction;
  isNavOpen: boolean;
  setNavOpen: (open: boolean) => void;
};

export const SettingsContext = createContext({} as SettingsContextProps);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};

const STORAGE_KEY = 'settings';

type SettingsProviderProps = {
  children: React.ReactNode;
  defaultSettings: SettingsValueProps;
};

export function SettingsProvider({ children, defaultSettings }: SettingsProviderProps) {
  const { state, update } = useLocalStorage(STORAGE_KEY, defaultSettings);

  const [openDrawer, setOpenDrawer] = useState(false);

  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const [isNavOpen, setNavOpen] = useState(false);

  const memoizedValue: SettingsContextProps = useMemo(
    () => ({
      ...state,
      onUpdate: update,
      // Drawer
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
      // Nav
      isNavOpen,
      setNavOpen,
    }),
    [update, state, openDrawer, onCloseDrawer, onToggleDrawer, isNavOpen, setNavOpen]
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
