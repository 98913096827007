import { AccessLevelEnum, Permissions } from '@/context/auth/types';
import { useUserProfile } from '@/hooks/useUsers';

export const useHasPermission = ({
  accessLevels,
  name,
}: {
  accessLevels: AccessLevelEnum[];
  name: Permissions;
}) => {
  const { permissions } = useUserProfile();

  const permission = permissions.find(({ permission }) => permission === name);

  if (!permission) {
    return null;
  }

  return accessLevels.some((level) => permission.policy.orgs.allow.includes(level));
};
