import { Theme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export function select(theme: Theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: `${ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP}px`, // Calculated height
          width: '250px', // Width as a string
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          right: 10,
          width: 18,
          height: 18,
          top: 'calc(50% - 9px)',
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        icon: {
          right: 10,
          width: 18,
          height: 18,
          top: 'calc(50% - 9px)',
        },
      },
    },
  };
}
