import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ConfirmDialog } from '@/components/custom-dialog';

export default function SessionTimeoutModal({
  sessionDuration,
  showModalAt,
  onExtendSession,
  onLogout,
}: {
  sessionDuration: number;
  showModalAt: number;
  onExtendSession: VoidFunction;
  onLogout: VoidFunction;
}) {
  const [timeLeft, setTimeLeft] = useState(sessionDuration);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev > 0) return prev - 1;
        onLogout();
        return 0;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onLogout]);

  const formatCountdown = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const date = new Date(0, 0, 0, 0, minutes, seconds);
    return format(date, 'mm:ss');
  };

  return (
    <ConfirmDialog
      actionTitle="No"
      open={timeLeft <= showModalAt}
      onClose={onLogout}
      title="Session Timeout"
      content={
        <>
          <Typography pt={2}>Your session will expire in {formatCountdown()}.</Typography>
          <Typography>Do you want to continue your session?</Typography>
        </>
      }
      action={
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setTimeLeft(sessionDuration);
            onExtendSession();
          }}
        >
          Stay Logged in
        </Button>
      }
    />
  );
}
