import React from 'react';

import { AccessLevelEnum, Permissions } from '@/context/auth/types';
import { useHasPermission } from '@/hooks/usePermissions';

export default function PermissionGuard({
  accessLevels,
  children,
  name,
}: {
  accessLevels: AccessLevelEnum[];
  children: React.ReactNode;
  name: Permissions;
}) {
  const hasPermission = useHasPermission({ accessLevels, name });

  if (hasPermission) {
    return <>{children}</>;
  }
}
