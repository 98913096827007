export const getInitials = (input: string) => {
  let words: string[];

  if (input.includes('@')) {
    const emailNamePart = input.split('@')[0];
    words = emailNamePart.split('.');
  } else {
    words = input.split(' ');
  }

  return words
    .filter((word) => word)
    .map((word) => word.charAt(0).toUpperCase())
    .join('');
};
