import { useMemo } from 'react';

import SvgColor from '@/components/SvgColor';
import { paths } from '@/constants';
import { AccessLevelEnum, CUSTOMER_TYPE, Permissions } from '@/context/auth/types';
import { useHasPermission } from '@/hooks/usePermissions';
import { useUserProfile } from '@/hooks/useUsers';

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

export const ICONS = {
  blog: icon('ic_blog'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  banking: icon('ic_banking'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

export function useNavData() {
  const hasManageOrgsPermission = useHasPermission({
    accessLevels: [AccessLevelEnum.ALL, AccessLevelEnum.CHILDREN],
    name: Permissions.view_orgs,
  });

  const { customerType } = useUserProfile();

  // const hasCreateOrdersPermission = useHasPermission({
  //   accessLevel: AccessLevelEnum.CURRENT,
  //   name: Permissions.create_orders,
  // });

  return useMemo(
    () => [
      {
        items: [
          {
            title: 'Home',
            path: paths.home,
            icon: ICONS.dashboard,
          },
          {
            title: 'Orders',
            path: paths.orders,
            icon: ICONS.file,
            children:
              customerType === CUSTOMER_TYPE.lender
                ? [
                    {
                      title: 'Create New Order',
                      path: `${paths.orders}/create`,
                    },
                  ]
                : [],
          },
          ...(hasManageOrgsPermission && import.meta.env.VITE_VIEW_LENDERS === 'true'
            ? [
                {
                  title: 'Lender Management',
                  path: paths.lenders,
                  icon: ICONS.banking,
                },
              ]
            : []),
          // { title: 'Vendors', path: paths.vendors, icon: ICONS.blog },
          ...(customerType === CUSTOMER_TYPE.admin
            ? [
                {
                  title: 'Reports',
                  path: paths.reports,
                  icon: ICONS.analytics,
                },
              ]
            : []),
        ],
      },
    ],
    [hasManageOrgsPermission, customerType]
  );
}
