import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import { ConfirmDialogProps } from './types';

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  actionTitle,
  icon,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <Stack spacing={2}>
        {title && <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>}

        {content && (
          <DialogContent sx={{ typography: 'body2', pt: 6, fontWeight: 600 }}>
            <Stack direction="row" alignItems="center" spacing={2} p={2}>
              {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
              <span>{content}</span>
            </Stack>
          </DialogContent>
        )}

        <DialogActions>
          {action}

          <Button variant="contained" onClick={onClose}>
            {actionTitle}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}
