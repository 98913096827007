import { useCallback, useState } from 'react';

export const useBoolean = (
  defaultValue?: boolean
): {
  value: boolean;
  onTrue: VoidFunction;
  onFalse: VoidFunction;
  onToggle: VoidFunction;
  setValue: (value: boolean) => void;
} => {
  const [value, setValue] = useState(!!defaultValue);

  const onTrue = useCallback(() => setValue(true), []);

  const onFalse = useCallback(() => setValue(false), []);

  const onToggle = useCallback(() => setValue((prev) => !prev), []);

  return {
    value,
    onFalse,
    onToggle,
    onTrue,
    setValue,
  };
};
