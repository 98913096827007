import { useAuth0 } from '@auth0/auth0-react';
import { BaseAPI, Configuration, PartiesApi, UsersApi } from '@xpanse/authz-service-client';

import { UserWithClaims } from '@/types';

import fetchOverride from './fetch-override';

const useApiConfig = () => {
  const { user = {} } = useAuth0();
  const { tenant } = user as UserWithClaims;
  return new Configuration({
    basePath:
      import.meta.env.MODE === 'local-services'
        ? `/authz-service/api/${tenant}`
        : `${import.meta.env.VITE_AUTHZ_SERVICE_BASE_PATH}/${tenant}`,
    fetchApi: fetchOverride,
  });
};

const useWithPreMiddleWare = <T extends BaseAPI>(api: T) => {
  const { getIdTokenClaims } = useAuth0();
  return api.withPreMiddleware<T>(async (context) => {
    const headers = new Headers(context.init.headers);
    const tokenClaims = await getIdTokenClaims();
    const token = tokenClaims?.__raw;

    headers.set('Authorization', `Bearer ${token}`);
    context.init.headers = headers;

    return context;
  });
};

export const usePartiesApi = () => {
  const config = useApiConfig();
  return useWithPreMiddleWare(new PartiesApi(config));
};

export const useUsersApi = () => {
  const config = useApiConfig();
  return useWithPreMiddleWare(new UsersApi(config));
};
