export default async function fetchOverride(url: string, options: RequestInit): Promise<Response> {
  const response = await fetch(url, options);

  if (!response.ok) {
    const errorResponse = await response.json();
    const { message } = errorResponse;
    throw new Error(message);
  }

  return response;
}
