import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export default function ShadowBox({ children }: { children: React.ReactNode }) {
  return (
    <Container sx={{ py: 2, maxWidth: 'lg' }}>
      <Box
        boxShadow="0px 0px 2px 0px rgba(93, 102, 146, 0.18), 0px 12px 24px -4px rgba(93, 102, 146, 0.07)"
        borderRadius={2}
        height="100%"
      >
        {children}
      </Box>
    </Container>
  );
}
