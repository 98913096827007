import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useSettingsContext } from '@/context/settings';
import { paper } from '@/theme/css';

import Iconify from '../../Iconify';
import Scrollbar from '../../scrollbar/Scrollbar';
import BaseOptions from './BaseOption';

export default function SettingsDrawer() {
  const theme = useTheme();

  const settings = useSettingsContext();

  const labelStyles = {
    mb: 1.5,
    color: 'text.disabled',
    fontWeight: 'fontWeightSemiBold',
  };

  return (
    <Drawer
      anchor="right"
      open={settings.open}
      onClose={settings.onClose}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: 280,
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2, pr: 1, pl: 2.5 }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Settings
        </Typography>

        <IconButton onClick={settings.onClose}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>
          <div>
            <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
              Mode
            </Typography>

            <BaseOptions
              value={settings.themeMode}
              onChange={(newValue: string) => settings.onUpdate('themeMode', newValue)}
              options={['light', 'dark']}
              icons={['sun', 'moon']}
            />
          </div>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
}
