import { forwardRef } from 'react';

import { BoxProps } from '@mui/material/Box';
import Link from '@mui/material/Link';

import LargeLogo from '@/components/logo/LargeLogo';
import SmallLogo from '@/components/logo/SmallLogo';

import RouterLink from '../RouterLink';

interface LogoProps extends Omit<BoxProps, 'ref'> {
  small?: boolean;
  disabledLink?: boolean;
  image: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ small, disabledLink = false, image, ...other }, ref) => {
    if (disabledLink) {
      return small ? <SmallLogo src={image} {...other} /> : <LargeLogo src={image} {...other} />;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {small ? <SmallLogo src={image} {...other} /> : <LargeLogo src={image} {...other} />}
      </Link>
    );
  }
);

export default Logo;
