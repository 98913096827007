import merge from 'lodash/merge';
import { useMemo } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeOptions } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { useSettingsContext } from '@/context/settings';

// options
import { customShadows } from './custom-shadows';
import { componentsOverrides } from './overrides';
// system
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function MuiThemeProvider({ children }: Props) {
  const settings = useSettingsContext();

  const memoizedValue = useMemo(
    () => ({
      palette: {
        ...palette(settings.themeMode),
      },
      customShadows: {
        ...customShadows(settings.themeMode),
      },
      shadows: shadows(settings.themeMode),
      shape: { borderRadius: 8 },
      typography,
    }),
    [settings.themeMode]
  );

  const theme = createTheme(memoizedValue as ThemeOptions);

  theme.components = merge(componentsOverrides(theme));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
