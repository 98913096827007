import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CreateUserRequest,
  PatchUserRequest,
  SearchPartiesRequest,
  SendUserCreatedEmailRequest,
} from '@xpanse/authz-service-client';

import { usePartiesApi, useUsersApi } from '@/api/auth-z';
import { IUserProfile } from '@/context/auth/types';
import { useErrorNotification } from '@/hooks/useErrorNotification';

const queryKey = (searchPartiesRequest: SearchPartiesRequest) => [
  'users',
  { ...searchPartiesRequest },
];

export const useUserProfile = (): IUserProfile => {
  const { user } = useAuth0();

  return user && user['com.xpanse.starter.security.domain.UserProfile'];
};

export const useGetUsers = (searchPartiesRequest: SearchPartiesRequest) => {
  const partiesApi = usePartiesApi();
  return useQuery({
    queryKey: queryKey(searchPartiesRequest),
    queryFn: () => partiesApi.searchParties(searchPartiesRequest),
  });
};

export const useCreateUsers = (searchPartiesRequest: SearchPartiesRequest) => {
  const usersApi = useUsersApi();
  const queryClient = useQueryClient();
  const { reportError } = useErrorNotification();
  return useMutation({
    mutationFn: (users: CreateUserRequest[]) => usersApi.createUsers({ createUserRequest: users }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey(searchPartiesRequest) }),
    onError: (error) => reportError(error),
  });
};

export const useSendUserEmail = () => {
  const usersApi = useUsersApi();
  const { reportError } = useErrorNotification();
  return useMutation({
    mutationFn: (request: SendUserCreatedEmailRequest) => usersApi.sendUserCreatedEmail(request),
    onError: (error) => reportError(error),
  });
};

export const useUpdateUser = (searchPartiesRequest: SearchPartiesRequest) => {
  const usersApi = useUsersApi();
  const queryClient = useQueryClient();
  const { reportError } = useErrorNotification();
  return useMutation({
    mutationFn: (request: PatchUserRequest) => usersApi.patchUser({ patchUserRequest: request }),

    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey(searchPartiesRequest) }),

    onError: (error) => reportError(error),
  });
};

export const useDisableUsers = (searchPartiesRequest: SearchPartiesRequest) => {
  const usersApi = useUsersApi();
  const queryClient = useQueryClient();
  const { reportError } = useErrorNotification();
  return useMutation({
    mutationFn: (request: string[]) => usersApi.disableUsers({ requestBody: request }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey(searchPartiesRequest) }),
    onError: (error) => reportError(error),
  });
};

export const useEnableUsers = (searchPartiesRequest: SearchPartiesRequest) => {
  const usersApi = useUsersApi();
  const queryClient = useQueryClient();
  const { reportError } = useErrorNotification();
  return useMutation({
    mutationFn: (request: string[]) => usersApi.enableUsers({ requestBody: request }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey(searchPartiesRequest) }),
    onError: (error) => reportError(error),
  });
};
