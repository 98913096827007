import { Navigate } from 'react-router';

import { CUSTOMER_TYPE } from '@/context/auth/types';
import { useUserProfile } from '@/hooks/useUsers';

export default function RoleGuard({
  children,
  redirect,
  role,
}: {
  children: React.ReactNode;
  redirect?: string;
  role: CUSTOMER_TYPE;
}) {
  const { customerType } = useUserProfile();

  if (role !== customerType) {
    return redirect ? <Navigate to={redirect} /> : null;
  }

  return <>{children}</>;
}
